import { createSelector } from "@ngrx/store";
import { LaunchpadType } from "src/app/features/overlays/region/launchpad/container/launchpad.interfaces";
import { RootState } from "../root-reducers";

const overlayState = (state: RootState) => state.regionOverlay;

export const selectRegionOverlay = createSelector(overlayState, (state) => state.regionOverlay.current);

/** @deprecated: use `selectLaunchpadType` instead, which returns `"regulated | "marketplace"` instead of `"module" | "plugin"` */
export const selectLaunchpadOverlay = createSelector(overlayState, (state) => state.launchpadOverlay);
export const selectLaunchpadType = createSelector(
  overlayState,
  (state) => (state.launchpadOverlay === "module" ? "regulated" : "marketplace") as LaunchpadType
);
