import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, Observable, Subject, combineLatest } from "rxjs";
import { filter, startWith, take, takeUntil } from "rxjs/operators";
import { SLOT } from "src/app/common/enums/slot";
import { Division } from "src/app/core/interfaces/division";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { disabledIfNotTrue } from "src/app/slots/three-year-plan/common/validators/disabled-if-not-true.validator";
import { NotificationSquares } from "../../constants/notification-type";
import { NotificationSettingsService } from "../../services/notification-settings.service";
import { Tab } from "../notification-settings/notification-settings.component";

export interface INotificationFormModel {
  allowNotifyByEmail: boolean;
  allowNotifyBySMS: boolean;
  notifyByEmail?: {
    update?: boolean;
    startEnd?: boolean;
    intermediate?: boolean;
    operationNews?: boolean;
    serviceNews?: boolean;
    criticalNews?: boolean;
    dataStorage?: boolean;
  };
  notifyBySMS?: {
    startEnd?: boolean;
    update: boolean;
    intermediate?: boolean;
  };
  email?: string;
  phone?: string;
  verifyPhone?: boolean;
}
interface IIndeterminate<T> {
  startEnd?: T;
  update?: T;
  intermediate?: T;
  verifyPhone?: T;
  operationNews?: T;
  serviceNews?: T;
  criticalNews?: T;
  dataStorage?: T;
}
export interface INotificationSets {
  email: string;
  phone: Set<string>;
  verifyPhone: Set<boolean>;
  notifyByEmail: IIndeterminate<Set<boolean>>;
  notifyBySMS: IIndeterminate<Set<boolean>>;
}

@Component({
  selector: "app-notification-settings-edit",
  templateUrl: "./notification-settings-edit.component.html",
  styleUrls: ["./notification-settings-edit.component.scss"]
})
export class NotificationSettingsEditComponent implements OnDestroy, OnInit, AfterViewInit {
  @Input() public set vm(vm: {
    required: boolean;
    division: Division;
    initialValue: INotificationFormModel;
    selectedRegionIds: Array<string>;
    initialIndeterminateEmail: IIndeterminate<boolean>;
    initialIndeterminateSms: IIndeterminate<boolean>;
  }) {
    if (!vm?.selectedRegionIds?.length) {
      return;
    }

    this.required = vm.required;
    this.division = vm.division;
    this.oneRegionSelected = vm.selectedRegionIds.length === 1;
    this.initialValue = vm.initialValue;
    this.initialIndeterminateEmail = vm.initialIndeterminateEmail;
    this.initialIndeterminateSms = vm.initialIndeterminateSms;

    this.indeterminateSms$.next({ ...this.initialIndeterminateSms });
    this.indeterminateEmail$.next({ ...this.initialIndeterminateEmail });
    this.initForm(this.initialValue);
    this.initOneRegionFormBehaviour();
  }

  public required: boolean;
  public division: Division;
  public form: FormGroup;
  public oneRegionSelected = true;
  public notificationSquares = NotificationSquares;
  public emailCheckboxWarning: AbstractControl;
  public phoneCheckboxWarning: AbstractControl;
  public get showEmailToggle() {
    return this.notificationSettingsService.getTabNameValue() === SLOT.OUTAGE_MONITORING && !this.oneRegionSelected;
  }

  public get allowNotifyByEmail(): AbstractControl | null {
    return this.form?.get("allowNotifyByEmail");
  }

  public get allowNotifyBySMS(): AbstractControl | null {
    return this.form?.get("allowNotifyBySMS");
  }

  public get verifyPhoneControl(): AbstractControl | null {
    return this.phoneNotificationFormControl.get("verifyPhone");
  }

  public get emailStartEndControl(): AbstractControl | null {
    return this.emailNotificationFormControl.get("startEnd");
  }

  public get emailUpdateControl(): AbstractControl | null {
    return this.emailNotificationFormControl.get("update");
  }

  public get emailIntermediateControl(): AbstractControl | null {
    return this.emailNotificationFormControl.get("intermediate");
  }

  public get smsStartEndControl(): AbstractControl | null {
    return this.phoneNotificationFormControl.get("startEnd");
  }

  public get phoneNotificationFormControl(): FormGroup | null {
    return this.form.get("phoneNotificationFormControl") as FormGroup;
  }

  public get emailNotificationFormControl(): FormGroup | null {
    return this.form.get("emailNotificationFormControl") as FormGroup;
  }

  public get emailControl(): AbstractControl | null {
    return this.emailNotificationFormControl.get("email");
  }

  public tabName$: Observable<Tab>;
  public indeterminateSms$ = new BehaviorSubject<IIndeterminate<boolean>>({});
  public indeterminateEmail$ = new BehaviorSubject<IIndeterminate<boolean>>({});

  private readonly destroy$ = new Subject<void>();
  private initialValue: INotificationFormModel;
  private initialIndeterminateSms: IIndeterminate<boolean>;
  private initialIndeterminateEmail: IIndeterminate<boolean>;

  constructor(
    private fb: FormBuilder,
    private notificationSettingsService: NotificationSettingsService,
    private readonly analytics: AnalyticsService
  ) {}

  public ngOnInit(): void {
    this.tabName$ = this.notificationSettingsService.getTabName();
  }

  public ngAfterViewInit() {
    this.analytics.scanForForms();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(model: INotificationFormModel): void {
    const { allowNotifyByEmail, allowNotifyBySMS, email, phone, verifyPhone } = model;

    const notifyByEmail = {
      startEnd: Boolean(model.notifyByEmail?.startEnd),
      intermediate: Boolean(model.notifyByEmail?.intermediate),
      update: Boolean(model.notifyByEmail?.update),
      operationNews: Boolean(model.notifyByEmail?.operationNews),
      serviceNews: Boolean(model.notifyByEmail?.serviceNews),
      criticalNews: Boolean(model.notifyByEmail?.criticalNews),
      dataStorage: Boolean(model.notifyByEmail?.dataStorage)
    };
    const notifyBySMS = {
      startEnd: Boolean(model.notifyBySMS?.startEnd),
      intermediate: Boolean(model.notifyBySMS?.intermediate),
      update: Boolean(model.notifyBySMS?.update)
    };

    if (this.form) {
      this.form.patchValue({ allowNotifyByEmail, allowNotifyBySMS });
      this.phoneNotificationFormControl?.patchValue({
        phone,
        verifyPhone,
        ...notifyBySMS
      });

      this.emailNotificationFormControl.patchValue({
        email,
        ...notifyByEmail
      });
    } else {
      this.form = this.fb.group(
        {
          allowNotifyByEmail,
          allowNotifyBySMS
        },
        {
          validators: [
            (form: FormGroup) => {
              if (this.required && !form.value.allowNotifyByEmail && !form.value.allowNotifyBySMS) {
                return { required: true };
              }
              return null;
            }
          ]
        }
      );

      if (this.notificationSettingsService.getTabNameValue() === SLOT.THREE_YEAR_PLAN) {
        this.form.addControl(
          "emailNotificationFormControl",
          this.fb.group({
            email: [{ value: email, disabled: true }, Validators.required],
            startEnabled: [false],
            intermediateEnabled: [false],
            startEnd: [notifyByEmail.startEnd, disabledIfNotTrue("startEnabled")],
            intermediate: [notifyByEmail.intermediate, disabledIfNotTrue("intermediateEnabled")],
            dataStorage: [notifyByEmail.dataStorage]
          })
        );
        this.notificationSettingsService.configs
          .pipe(
            filter((configs) => Boolean(configs)),
            take(1),
            takeUntil(this.destroy$)
          )
          .subscribe((configs) => {
            this.emailNotificationFormControl.get("startEnabled").patchValue(configs.start);
            this.emailNotificationFormControl.get("intermediateEnabled").patchValue(configs.intermediate);
          });
      } else if (this.notificationSettingsService.getTabNameValue() === "street-lighting") {
        this.form.addControl(
          "emailNotificationFormControl",
          this.fb.group({
            email: [{ value: email, disabled: true }, Validators.required],
            startEnd: [notifyByEmail.startEnd],
            intermediate: [notifyByEmail.intermediate]
          })
        );
      } else if (this.notificationSettingsService.getTabNameValue() === "accounting") {
        this.form.addControl(
          "emailNotificationFormControl",
          this.fb.group({
            email: [{ value: email, disabled: true }, Validators.required],
            startEnd: [notifyByEmail.startEnd],
            update: [notifyByEmail.update],
            intermediate: [notifyByEmail.intermediate]
          })
        );
      } else if (this.notificationSettingsService.getTabNameValue() === "kwp") {
        this.form.addControl(
          "emailNotificationFormControl",
          this.fb.group({
            email: [{ value: email, disabled: true }, Validators.required],
            intermediate: [notifyByEmail.intermediate]
          })
        );
      } else if (this.notificationSettingsService.getTabNameValue() === "news") {
        this.form.addControl(
          "emailNotificationFormControl",
          this.fb.group({
            email: [{ value: email, disabled: true }, Validators.required],
            operationNews: [notifyByEmail.operationNews],
            serviceNews: [notifyByEmail.serviceNews],
            criticalNews: [notifyByEmail.criticalNews]
          })
        );
      } else {
        this.form.addControl(
          "emailNotificationFormControl",
          this.fb.group({
            email: [{ value: email, disabled: true }, Validators.required],
            startEnd: [notifyByEmail.startEnd, disabledIfNotTrue("email")],
            intermediate: [notifyByEmail.intermediate, disabledIfNotTrue("startEnd")],
            update: [notifyByEmail.update, disabledIfNotTrue("startEnd")]
          })
        );
        this.form.addControl(
          "phoneNotificationFormControl",
          this.fb.group({
            phone: [phone],
            verifyPhone: [verifyPhone, [disabledIfNotTrue("phone", true), Validators.requiredTrue]],
            startEnd: [notifyBySMS.startEnd, disabledIfNotTrue("verifyPhone")],
            intermediate: [notifyBySMS.intermediate, disabledIfNotTrue("startEnd")],
            update: [notifyBySMS.update, disabledIfNotTrue("startEnd")]
          })
        );
      }
    }
  }

  private initOneRegionFormBehaviour(): void {
    combineLatest([
      this.allowNotifyByEmail.valueChanges.pipe(startWith(this.allowNotifyByEmail?.value)),
      this.allowNotifyBySMS.valueChanges.pipe(startWith(this.allowNotifyBySMS?.value))
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([allowNotifyByEmail, allowNotifyBySMS]) => {
        if (!allowNotifyByEmail) {
          const notifyByEmail = {
            email: this.initialValue.email,
            startEnd: Boolean(this.initialValue.notifyByEmail?.startEnd),
            intermediate: Boolean(this.initialValue.notifyByEmail?.intermediate),
            update: Boolean(this.initialValue.notifyByEmail?.update)
          };

          this.emailNotificationFormControl.patchValue(notifyByEmail, { emitEvent: false });
          this.indeterminateEmail$.next({ ...this.initialIndeterminateEmail });
          this.emailNotificationFormControl.disable({ emitEvent: false });
          this.emailNotificationFormControl.updateValueAndValidity();
        } else {
          this.emailNotificationFormControl.enable({ emitEvent: false });
          this.emailControl.disable();

          Object.keys(this.emailNotificationFormControl.controls).forEach((key) => {
            this.emailNotificationFormControl.get(key).updateValueAndValidity();
          });
        }

        if (!this.phoneNotificationFormControl) {
          return;
        }

        if (!allowNotifyBySMS) {
          const notifyBySMS = {
            phone: this.initialValue.phone,
            verifyPhone: this.initialValue.verifyPhone,
            startEnd: Boolean(this.initialValue.notifyBySMS?.startEnd),
            intermediate: Boolean(this.initialValue.notifyBySMS?.intermediate),
            update: Boolean(this.initialValue.notifyBySMS?.update)
          };

          this.phoneNotificationFormControl?.patchValue(notifyBySMS, { emitEvent: false });
          this.indeterminateSms$.next({ ...this.initialIndeterminateSms });

          this.phoneNotificationFormControl.disable({ emitEvent: false });
          this.phoneNotificationFormControl.updateValueAndValidity();
        } else {
          this.phoneNotificationFormControl.enable({ emitEvent: false });
          Object.keys(this.phoneNotificationFormControl.controls).forEach((key) => {
            this.phoneNotificationFormControl.get(key).updateValueAndValidity();
          });
        }
      });
  }
}
