import { Injectable, inject } from "@angular/core";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest } from "rxjs";
import { filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { LaunchpadItemDto, LaunchpadResponse } from "src/app/common/dto/admin-module/launchpad";
import { PLUGIN } from "src/app/common/enums/plugins";
import { LoadingResponse } from "src/app/common/interfaces/loading-response";
import { CRM_MODULES_IDS_MAP } from "src/app/core/constants/modules-crm-ids-map";
import {
  CrmModuleNamePluginMap,
  MarketplaceBannerEntry,
  OrderFormEntry,
  SalesInfoEntry
} from "src/app/core/interfaces/contentful";
import { AnyModuleType, toCrmModule } from "src/app/core/services/analytics/analytics.service";
import { CampusOneDataService } from "src/app/core/services/marketplace/campus-one-data.service";
import { changeLaunchpadOverlay } from "src/app/ngrx/actions/overlay.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectLaunchpadModules } from "src/app/ngrx/selectors/admin-data.selectors";
import { selectRegionAgs } from "src/app/ngrx/selectors/app.selectors";
import { selectLaunchpadType } from "src/app/ngrx/selectors/overlay.selectors";
import { selectPermissionsValues } from "src/app/ngrx/selectors/permissions.selectors";
import { NewsService } from "../../news/news.service";
import { LaunchpadTileConfig, LaunchpadType } from "./launchpad.interfaces";
import { convertToLegacyOverlay } from "./launchpad.utils";

export interface LaunchpadState {
  launchpadResponse: LoadingResponse<LaunchpadResponse>;
  marketplaceBanner: LoadingResponse<
    Array<MarketplaceBannerEntry & { salesInfo?: SalesInfoEntry; orderForm?: OrderFormEntry }>
  >;
  salesInfos: null | Array<SalesInfoEntry>;
  orderForms: null | Array<OrderFormEntry>;
}

@Injectable()
export class LaunchpadComponentStore extends ComponentStore<LaunchpadState> {
  private readonly store = inject(Store<RootState>);
  private readonly newService = inject(NewsService);
  private readonly campusOneDataService = inject(CampusOneDataService);
  private readonly translate = inject(TranslateService);

  constructor() {
    super({
      launchpadResponse: {
        result: {
          modules: [],
          outageDashboard: false
        },
        loading: false
      },
      marketplaceBanner: {
        result: [],
        loading: false
      },
      salesInfos: null,
      orderForms: null
    });
  }

  public readonly overlay$ = this.store.select(selectLaunchpadType);
  public readonly launchpadModules$ = this.store.select(selectLaunchpadModules);
  public readonly marketplaceBanner$ = this.select((state) => state.marketplaceBanner);
  public readonly salesInfos$ = this.select((state) => state.salesInfos);
  public readonly orderForms$ = this.select((state) => state.orderForms);
  public readonly mergedList$ = this.select({
    overlay: this.overlay$,
    modules: this.launchpadModules$,
    salesInfos: this.salesInfos$,
    orderForms: this.orderForms$
  }).pipe(
    switchMap(({ overlay, modules, salesInfos, orderForms }) =>
      this.selectPermissions(modules.result).pipe(
        map((permissions) => ({
          loading: modules.loading,
          result: modules.result.map((item) =>
            this.createLaunchpadTileConfig(
              overlay,
              permissions[`${item.name}.read`],
              permissions[`${item.name}.admin`],
              salesInfos,
              orderForms,
              item
            )
          )
        }))
      )
    )
  );

  // its only for campus one now, that only entry create in contentful
  public readonly mergedBanner$ = this.select({
    overlay: this.overlay$,
    marketplaceBanner: this.marketplaceBanner$
  }).pipe(
    map(({ overlay, marketplaceBanner }) => ({
      loading: marketplaceBanner.loading,
      result:
        overlay === "marketplace"
          ? marketplaceBanner.result.map((banner) => ({
              moduleId: banner.fields.moduleId,
              linkUrl: banner.fields.linkUrl,
              logo: (banner.fields.logo?.fields as any)?.file.url,
              teaserInfo: banner.fields.teaserInfo,
              teaserImage: (banner.fields.teaserImage?.fields as any)?.file.url,
              price: banner.fields.price,
              teaserAddition: banner.fields.teaserAddition
            }))
          : []
    }))
  );
  public readonly news$ = this.newService.getNewsViewModel();
  public readonly vm$ = this.select({
    overlay: this.overlay$,
    list: this.mergedList$,
    news: this.news$,
    marketplaceBanner: this.mergedBanner$
  });

  public readonly changeOverlay = this.effect<LaunchpadType>((params$) =>
    params$.pipe(
      withLatestFrom(this.store.pipe(select(selectRegionAgs))),
      tap(([overlay, ags]) => {
        this.store.dispatch(changeLaunchpadOverlay({ overlay: convertToLegacyOverlay(overlay) }));
      })
    )
  );

  // for now its only for `Campus One`
  public readonly fetchMarketplaceBanner = this.effect<string>((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.patchState({ marketplaceBanner: { result: [], loading: true } });
      }),
      switchMap((regionId) =>
        this.campusOneDataService.getAdvertisingContent("marketplaceBanner", regionId).pipe(
          tapResponse(
            (result) => {
              const orderedResult = result.sort(
                (a, b) => new Date(b.sys.createdAt).getTime() - new Date(a.sys.createdAt).getTime()
              );
              this.patchState({ marketplaceBanner: { result: orderedResult, loading: false } });
            },
            () => {
              this.patchState({ marketplaceBanner: { result: [], loading: false } });
            }
          )
        )
      )
    )
  );

  public readonly fetchSalesInfos = this.effect<string>((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.patchState({ salesInfos: null });
      }),
      switchMap((regionId) =>
        this.campusOneDataService.getAdvertisingContent("salesInfo", regionId).pipe(
          tapResponse(
            (result) => {
              this.patchState({ salesInfos: result });
            },
            () => {
              this.patchState({ salesInfos: null });
            }
          )
        )
      )
    )
  );

  public readonly fetchOrderForms = this.effect<string>((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.patchState({ orderForms: null });
      }),
      switchMap((regionId) =>
        this.campusOneDataService.getAdvertisingContent("orderForm", regionId).pipe(
          tapResponse(
            (result) => {
              this.patchState({ orderForms: result });
            },
            () => {
              this.patchState({ orderForms: null });
            }
          )
        )
      )
    )
  );

  public readonly getPopulatedSalesInfosAndOrderForms$ = combineLatest([this.salesInfos$, this.orderForms$]).pipe(
    filter(([salesInfos, orderForms]) => salesInfos != null && orderForms != null)
  );

  /**
   * This function will update the translations (module title and description) for the modules in the launchpad.
   * The values are fetched from the `admin-module` service.
   */
  public updateModuleTranslations() {
    this.launchpadModules$.subscribe(async (modules) => {
      const translationMap = modules.result.reduce(
        (acc, module) => ({
          ...acc,
          [module.name]: {
            name: module.title,
            description: module.description
          }
        }),
        {}
      );
      this.translate.setTranslation(this.translate.currentLang, { LAUNCHPAD: { PLUGINS: translationMap } }, true);
    });
  }

  public getSalesInfoModuleId(salesInfo: SalesInfoEntry) {
    const moduleName = salesInfo.fields.moduleName as any as AnyModuleType;
    const pluginName: PLUGIN = CrmModuleNamePluginMap[moduleName];
    return toCrmModule(pluginName ?? moduleName);
  }

  public getOrderFormModuleId(orderForm: OrderFormEntry) {
    const moduleName = orderForm.fields.moduleId as any as AnyModuleType;
    const pluginName: PLUGIN = CrmModuleNamePluginMap[moduleName];
    return toCrmModule(pluginName ?? moduleName);
  }

  private selectPermissions(list: Array<LaunchpadItemDto>) {
    return this.store.select(selectPermissionsValues, {
      permissions: list.flatMap(({ name }) => [`${name}.read`, `${name}.admin`])
    });
  }

  private createLaunchpadTileConfig(
    overlay: LaunchpadType,
    hasReadPermission: boolean,
    hasAdminPermission: boolean,
    salesInfos: SalesInfoEntry[],
    orderForms: OrderFormEntry[],
    props: LaunchpadItemDto
  ): LaunchpadTileConfig {
    const isPositiveStatus = props.status !== "TERMINATED" && props.status !== "PENDING";
    const isActiveStatus = "status" in props && isPositiveStatus;
    const isActiveTile = (!("status" in props) || isPositiveStatus) && hasReadPermission;
    const moduleId = CRM_MODULES_IDS_MAP[props.name] ?? props.name;

    const salesInfoForModule = salesInfos?.find((salesInfo) => this.getSalesInfoModuleId(salesInfo) === props.name);
    const orderFormForModule = orderForms?.find((orderForm) => this.getOrderFormModuleId(orderForm) === props.name);
    const hasSalesInfoOrOrderForm = Boolean(salesInfoForModule || orderFormForModule);

    switch (overlay) {
      case "regulated": {
        return { ...props, moduleId, overlay, active: isActiveTile, showStoryTellerButton: true };
      }
      case "marketplace": {
        return {
          ...props,
          moduleId,
          overlay,
          active: isActiveTile,
          showLeadButton:
            props.status !== "PENDING" && !isActiveStatus && !hasReadPermission && hasSalesInfoOrOrderForm,
          salesInfo: salesInfoForModule,
          orderForm: orderFormForModule,
          showInProgress: props.status === "PENDING" || (isActiveStatus && !hasReadPermission),
          showProductManagementButton: hasAdminPermission
        };
      }
    }
  }
}
