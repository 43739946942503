import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { combineLatest, first, map, switchMap } from "rxjs";
import { AdminDataService } from "src/app/core/services/admin-data.service";
import * as AdminDataActions from "../actions/admin-data.actions";
import { selectLaunchpadModulesByType } from "../selectors/admin-data.selectors";
import { selectRegionAgs } from "../selectors/app.selectors";
import { selectLaunchpadType } from "../selectors/overlay.selectors";

export const invalidateLaunchpadEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return combineLatest([store.select(selectLaunchpadType), store.select(selectRegionAgs)]).pipe(
      switchMap(([launchpadType, ags]) => {
        return store.select(selectLaunchpadModulesByType(launchpadType)).pipe(
          first(),
          map((currentState) => {
            if (currentState.ags !== ags || !currentState.loaded) {
              return AdminDataActions.loadLaunchpad({ launchpadType, ags });
            }
            return { type: "NO_ACTION" }; // No action needed
          })
        );
      })
    );
  },
  { functional: true }
);

export const loadLaunchpadEffect = createEffect(
  (actions$ = inject(Actions), adminDataService = inject(AdminDataService)) => {
    return actions$.pipe(
      ofType(AdminDataActions.loadLaunchpad),
      switchMap(({ launchpadType, ags }) =>
        adminDataService
          .getLaunchpadList(launchpadType, ags)
          .pipe(map((response) => AdminDataActions.launchpadLoadedSuccess({ launchpadType, ags, response })))
      )
    );
  },
  { functional: true }
);
