import { createAction, props } from "@ngrx/store";
import { LaunchpadResponse } from "src/app/common/dto/admin-module/launchpad";
import { LaunchpadType } from "src/app/features/overlays/region/launchpad/container/launchpad.interfaces";

export const loadLaunchpad = createAction(
  "[Admin Data] Load launchpad",
  props<{ launchpadType: LaunchpadType; ags: string }>()
);
export const launchpadLoadedSuccess = createAction(
  "[Admin Data] Launchpad loaded success",
  props<{ launchpadType: LaunchpadType; ags: string; response: LaunchpadResponse }>()
);
