<div class="tiles-grid-wrapper" [@inListAnimation]="list?.length">
  <tpm-tile
    *ngFor="let item of list; trackBy: trackByName"
    [isMobile]="isMobile"
    [props]="item"
    [attr.data-cy]="item.moduleId"
    (tileClicked)="tileClicked.emit(item)"
    (leadClicked)="leadClicked.emit(item)"
    (productManagementClicked)="productManagementClicked.emit(item)"
    (storyTellerClicked)="storyTellerClicked.emit(item)"
  >
    <tpm-news-card
      *ngIf="item.active && news && (news[item.moduleId] ?? news[item.name])"
      [props]="news[item.moduleId] ?? news[item.name]"
      (actionClicked)="newsCardClicked.emit($event)"
    ></tpm-news-card>
  </tpm-tile>
  <links-card (newsClicked)="newsClicked.emit()" (subscriptionClicked)="subscriptionClicked.emit()"></links-card>
</div>
