<div
  [ngClass]="{ ellipsis: columnConfig.actionType === 'text' }"
  [ngStyle]="{ display: isButtonHide(columnConfig, row) ? 'none' : null }"
>
  <button
    *ngIf="!columnConfig.actionIcon && !columnConfig.actionType"
    ene-outline-button
    [disabled]="isButtonDisabled(columnConfig, row)"
    [matTooltipDisabled]="isTooltipDisabled(columnConfig, row)"
    [matTooltipClass]="['table-tooltip', columnConfig.id + '-tooltip']"
    [matTooltip]="tooltipText(columnConfig, row) | translate"
    (click)="onClick()"
  >
    {{ buttonLabel(columnConfig, row) | translate }}
  </button>
  <button
    *ngIf="columnConfig.actionIcon"
    ene-button
    [icon]="columnConfig.actionIcon"
    [disabled]="isButtonDisabled(columnConfig, row)"
    [matTooltipDisabled]="isTooltipDisabled(columnConfig, row)"
    [matTooltipClass]="['table-tooltip', columnConfig.id + '-tooltip']"
    [matTooltip]="tooltipText(columnConfig, row) | translate"
    (click)="onClick()"
  ></button>
  <button
    *ngIf="!columnConfig.actionIcon && columnConfig.actionType === 'filled'"
    ene-filled-button
    [disabled]="isButtonDisabled(columnConfig, row)"
    [matTooltipDisabled]="isTooltipDisabled(columnConfig, row)"
    [matTooltipClass]="['table-tooltip', columnConfig.id + '-tooltip']"
    [matTooltip]="tooltipText(columnConfig, row) | translate"
    (click)="onClick()"
  >
    {{ buttonLabel(columnConfig, row) | translate }}
  </button>
  <span
    *ngIf="!columnConfig.actionIcon && columnConfig.actionType === 'text'"
    tooltipIfTruncated
    ene-outline-button
    [matTooltipClass]="'light-tooltip'"
    [matTooltip]="buttonLabel(columnConfig, row) | translate"
    (click)="onClick()"
  >
    {{ buttonLabel(columnConfig, row) | translate }}
  </span>
</div>
