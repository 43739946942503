import { ITableColumn } from "src/app/common/components/table/interfaces/table-column";

export const notificationSettingsKWP: Array<ITableColumn> = [
  {
    id: "municipality",
    key: "NOTIFICATION_SERVICE.TABLE_COLUMNS.ALL_MUNICIPALITIES",
    type: "text"
  },
  {
    id: "email",
    key: "NOTIFICATION_SERVICE.TABLE_COLUMNS.EMAIL",
    type: "text"
  },
  {
    id: "edit",
    key: "",
    type: "action",
    actionIcon: "ec_edit",
    columnStyles: "grey",
    style: {
      minWidth: "3rem",
      maxWidth: "3rem"
    },
    stickyEnd: true
  }
];
