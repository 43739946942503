export enum PLUGIN {
  STREET_LIGHTING = "streetLighting",
  SHOPPING_CART = "shoppingCart",
  NOYSEE = "noysee",
  KEM = "kem",
  CO2_BALANCE = "co2Balance",
  DIGINAMIC = "diginamic",
  CAMPUS_ONE = "campusOne",
  QUICK_CHECK = "quickCheck",
  KWP = "kwp"
}
