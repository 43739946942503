import { createReducer, on } from "@ngrx/store";
import { LaunchpadResponse } from "src/app/common/dto/admin-module/launchpad";
import { LoadingResponse } from "src/app/common/interfaces/loading-response";
import { LaunchpadType } from "src/app/features/overlays/region/launchpad/container/launchpad.interfaces";
import * as AdminDataActions from "../actions/admin-data.actions";

export interface LoadingResponseWithAgs<T> extends LoadingResponse<T> {
  ags: string;
}

export interface AdminDataState {
  modules: Record<LaunchpadType, LoadingResponseWithAgs<LaunchpadResponse["modules"]>>;
  outageDashboard: boolean | null;
}

export const initialState: AdminDataState = {
  modules: {
    regulated: {
      ags: "",
      result: [],
      loading: false
    },
    marketplace: {
      ags: "",
      result: [],
      loading: false
    }
  },
  outageDashboard: null
};

export const adminDataReducer = createReducer(
  initialState,
  on(AdminDataActions.loadLaunchpad, (state, { ags, launchpadType }) => ({
    ...state,
    modules: {
      ...state.modules,
      [launchpadType]: { result: [], ags, loaded: false, loading: true }
    }
  })),
  on(AdminDataActions.launchpadLoadedSuccess, (state, { launchpadType, ags, response }) => ({
    ...state,
    modules: {
      ...state.modules,
      [launchpadType]: { result: response.modules, ags, loading: false, loaded: true }
    },
    outageDashboard: response.outageDashboard ?? state.outageDashboard
  }))
);
