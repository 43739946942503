import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { KeycloakService } from "keycloak-angular";
import { KeycloakTokenParsed } from "keycloak-js";
import { Observable, Subject, delay, from, interval, map, of, share, switchMap, take, takeUntil } from "rxjs";
import { ClientConfigDto, IClientConfigDto } from "src/app/common/dto/client-config";
import { KeycloakUserInfoDto } from "src/app/common/dto/keycloak/keycloak-user-info";
import { UserInfoDto } from "src/app/common/dto/user-info";
import { IPolicy } from "src/app/common/interfaces/app/policy";
import { setUserInfo } from "src/app/ngrx/actions/app.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { AppConfig } from "../helpers/app.config";
import { CookieService } from "./cookie.service";

export const AUTH_PROVIDER = {
  provide: KeycloakService,
  useValue: new KeycloakService()
};

export const LOCATION_TOKEN = new InjectionToken<Location>("Window location object");
export const POLICY_COOKIES_TOKEN = "POLICY";

@Injectable({ providedIn: "root" })
export class AuthService implements OnDestroy {
  protected readonly destroy$ = new Subject<void>();
  private userInfo$: Observable<UserInfoDto>;
  private readonly COUNT_OF_DAYS_UNTIL_EXPIRE = 365;

  constructor(
    private readonly store: Store<RootState>,
    private readonly keycloak: KeycloakService,
    private readonly cookieService: CookieService,
    private readonly http: HttpClient,
    @Inject(LOCATION_TOKEN) private location: Location
  ) {
    // update token if it will expired in 30secs. This is needed because the map doesnt support interceptor.
    interval(1000 * 15)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.keycloak.updateToken(30));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getToken(): string {
    return this.keycloak.getKeycloakInstance().token;
  }

  public hasPolicy(currentPolicyVersion: string): boolean {
    const cookies = this.getPolicyCookies();
    return !!Object.values(cookies).length && cookies?.version === currentPolicyVersion;
  }

  public setPolicyCookies(value: IPolicy): void {
    this.cookieService.set(POLICY_COOKIES_TOKEN, JSON.stringify(value), this.COUNT_OF_DAYS_UNTIL_EXPIRE);
  }

  public deletePolicyCookies(): void {
    this.cookieService.delete(POLICY_COOKIES_TOKEN);
  }

  public getPolicyCookies(): IPolicy {
    return JSON.parse(this.cookieService.get(POLICY_COOKIES_TOKEN) || "{}");
  }

  public init(): Observable<boolean> {
    return from(
      this.keycloak.init({
        config: AppConfig.connection.keycloakConfig.config,
        initOptions: {
          onLoad: "check-sso",
          silentCheckSsoRedirectUri: this.location.origin + "/assets/silent-check-sso.html",
          checkLoginIframe: false
        },
        enableBearerInterceptor: true,
        loadUserProfileAtStartUp: false,
        bearerExcludedUrls: ["assets"],
        bearerPrefix: "Bearer"
      })
    );
  }

  public login(): Observable<void> {
    return from(
      this.keycloak.login({
        idpHint: this.getIdpHint()
      })
    );
  }

  public logout(): void {
    this.keycloak.logout();
  }

  public getKeycloakUserInfo(): Observable<KeycloakUserInfoDto> {
    if (!this.userInfo$) {
      this.userInfo$ = from(
        new Promise<any>((resolve, reject) =>
          this.keycloak.getKeycloakInstance().loadUserInfo().then(resolve).catch(reject)
        )
      ).pipe(share());
    }

    return this.userInfo$.pipe(map((userInfo) => new KeycloakUserInfoDto(userInfo)));
  }

  public getUserInfo(update: boolean = false): Observable<UserInfoDto> {
    if (!this.userInfo$ || update === true) {
      this.userInfo$ = from(
        new Promise<any>((resolve, reject) =>
          this.keycloak.getKeycloakInstance().loadUserInfo().then(resolve).catch(reject)
        )
      ).pipe(share());
    }

    return this.userInfo$.pipe(
      map((userInfo) => ({
        ...userInfo,
        permissions: {
          scoped: userInfo.permissions?.scoped || {}
        }
      }))
    );
  }

  public refreshUserInfo(delayed: number = 1000): void {
    of(delayed)
      .pipe(
        delay(delayed),
        switchMap(() => this.getUserInfo(true)),
        take(1)
      )
      .subscribe((userInfo) => {
        this.store.dispatch(setUserInfo({ userInfo }));
      });
  }

  public getClientConfig(): Observable<ClientConfigDto> {
    const url = `${AppConfig.connection.config}/login/clientConfig`;

    return this.http.get<IClientConfigDto>(url).pipe(map((response) => new ClientConfigDto(response)));
  }

  public getParsedToken(): KeycloakTokenParsed & { email: string; hasRegionAssigned: boolean; user_type: string } {
    return this.keycloak.getKeycloakInstance().tokenParsed as KeycloakTokenParsed & {
      email: string;
      hasRegionAssigned: boolean;
      user_type: string;
    };
  }

  public tokenExchange(clientId: string): Observable<{
    access_token: string;
    expires_in: number;
  }> {
    return from(
      fetch((this.keycloak.getKeycloakInstance() as any).endpoints.token(), {
        method: "POST",
        body: new URLSearchParams({
          grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
          subject_token: this.keycloak.getKeycloakInstance().token,
          client_id: "frontend",
          audience: clientId,
          requested_token_type: "urn:ietf:params:oauth:token-type:access_token",
          subject_token_type: "urn:ietf:params:oauth:token-type:access_token"
        })
      })
    ).pipe(switchMap((response) => from(response.json())));
  }

  private getIdpHint(): string | undefined {
    const noIdpHint = new URLSearchParams(window.location.search).get("noIdpHint");
    if (noIdpHint) {
      return undefined;
    }
    const idpHint = new URLSearchParams(window.location.search).get("idp_hint");
    if (idpHint) {
      return idpHint;
    }
    return AppConfig.connection.keycloakConfig.idpHint;
  }
}
