export enum FeatureFlag {
  DualRolesPortal = "kp.dualRolesPortal",
  NoyseeSSO = "kp.noysee-sso-v2",
  ACCArchiveColumns = "kp.auv.archive-columns",
  GCUpdateCycleIcon = "kp.update-cycle-icon",
  GCCockpit = "kp.eun.monthly-cockpit",
  GCChartFeedIn = "kp.chart-feed-in",
  GCSmartMeter = "kp.smart-meter",
  GCChartConsumption = "kp.chart-consumption",
  TYPPointsOfContact = "kp.3yp.points-of-contact",
  OMPointsOfContact = "kp.om.points-of-contact"
}
