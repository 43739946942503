import { createSelector } from "@ngrx/store";
import { UserInfoDto } from "src/app/common/dto/user-info";
import { CrmModule } from "src/app/core/enums/permissions";
import { selectRegionId, selectUserInfo } from "./app.selectors";

const checkPermissions = (
  permissionsList: Array<string>,
  permissions: Array<string> | string,
  method: "some" | "every" = "every",
  compareBy: "equals" | "startsWith" = "equals"
): boolean => {
  const _permissions = Array.isArray(permissions) ? permissions : [permissions];
  const hasEveryPermissions = _permissions[method]((name) =>
    compareBy === "equals"
      ? permissionsList.includes(name)
      : permissionsList.some((permission) => permission.startsWith(name))
  );

  return hasEveryPermissions;
};

export const hasRegionPermissions = createSelector(
  selectUserInfo,
  selectRegionId,
  (userInfo: UserInfoDto, regionId: string, { permissions }: { permissions: Array<string> | string }): boolean => {
    if (!userInfo || !regionId) {
      return false;
    }

    return checkPermissions(userInfo.permissions.scoped[`region:${regionId}`] || [], permissions);
  }
);

export const someRegionsHasPermissions = createSelector(
  selectUserInfo,
  (
    userInfo: UserInfoDto,
    {
      permissions,
      method,
      compareBy
    }: { permissions: Array<string> | string; method?: "some" | "every"; compareBy?: "equals" | "startsWith" }
  ): boolean => {
    if (!userInfo) {
      return false;
    }
    return Object.values(userInfo.permissions.scoped).some((permissionsList) =>
      checkPermissions(permissionsList, permissions, method, compareBy)
    );
  }
);

export const hasPermissions = createSelector(
  selectUserInfo,
  (
    userInfo: UserInfoDto,
    { permissions, regionId }: { permissions: Array<string> | string; regionId: string }
  ): boolean => {
    if (!userInfo || !regionId) {
      return false;
    }

    return checkPermissions(userInfo.permissions.scoped[`region:${regionId}`] || [], permissions);
  }
);

export const selectPermissionsValues = createSelector(
  selectUserInfo,
  selectRegionId,
  (
    userInfo: UserInfoDto,
    regionId: string,
    { permissions }: { permissions: Array<string> | string }
  ): Record<string, boolean> => {
    const _permissions = Array.isArray(permissions) ? permissions : [permissions];

    if (!userInfo || !regionId) {
      return _permissions.reduce(
        (accPermissions, name) => ({
          ...accPermissions,
          [name]: false
        }),
        {}
      );
    } else {
      return _permissions.reduce(
        (accPermissions, name) => ({
          ...accPermissions,
          [name]: (userInfo.permissions.scoped[`region:${regionId}`] || []).includes(name)
        }),
        {}
      );
    }
  }
);

export const getNotificationSettingsPermissions = (module: CrmModule): Array<string> => [
  `${module}.subscriptions.sms.read`,
  `${module}.subscriptions.sms.admin.read`,
  `${module}.subscriptions.sms.edit`,
  `${module}.subscriptions.email.read`,
  `${module}.subscriptions.email.admin.read`,
  `${module}.subscriptions.email.edit`
];

export const hasNotificationSettingsPermissions = createSelector(
  selectUserInfo,
  selectRegionId,
  (
    userInfo: UserInfoDto,
    regionId: string,
    modules: Array<CrmModule> = [
      CrmModule.OutageMonitoring,
      CrmModule.ThreeYearPlan,
      CrmModule.Accounting,
      CrmModule.StreetLighting,
      CrmModule.KWP
    ]
  ): boolean => {
    if (!userInfo || !regionId) {
      return false;
    }

    return modules.some((module) =>
      checkPermissions(
        userInfo.permissions.scoped[`region:${regionId}`] || [],
        getNotificationSettingsPermissions(module),
        "some"
      )
    );
  }
);

export const hasActiveFeatureFlag = (flagName: string) =>
  createSelector(selectUserInfo, (userInfo: UserInfoDto): boolean => {
    if (!userInfo || !userInfo.featureFlags) {
      return false;
    }
    const attributeArray = Array.isArray(userInfo.featureFlags) ? userInfo.featureFlags : [userInfo.featureFlags];
    const flags = attributeArray?.flatMap((values) => values.split(",")).map((value) => value.trim());
    return flags?.includes(flagName) ?? false;
  });
