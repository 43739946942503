import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { TabComponent } from "@enersis/ui-tabs";
import { Observable, Subject, merge } from "rxjs";
import { map, scan, startWith, switchMap } from "rxjs/operators";
import { PaginatorDefaultOptions } from "src/app/common/components/paginator/paginator.component";
import { PLUGIN } from "src/app/common/enums/plugins";
import { SLOT } from "src/app/common/enums/slot";
import { LoadingResponse } from "src/app/common/interfaces/loading-response";
import { getFileSize } from "src/app/common/utils/file-size";
import { ModuleConfigLogsItem } from "src/app/core/interfaces/project-entity";
import { TableAction } from "src/app/slots/three-year-plan/common/components/table/table.interfaces";
import { configurationAreaDownloadsColumnConfig } from "./configuration-area-downloads.constants";
import { ConfigurationAreaDownloadsVM } from "./configuration-area-downloads.interfaces";
import { ConfigurationAreaDownloadsService, LogModules } from "./configuration-area-downloads.service";

interface TabModule {
  value: LogModules;
  label: string;
  showFor: Array<"logs" | "reports">;
}

const moduleList: Array<TabModule> = [
  {
    value: SLOT.THREE_YEAR_PLAN,
    label: `LAUNCHPAD.MODULES.${SLOT.THREE_YEAR_PLAN}.name`,
    showFor: ["logs", "reports"]
  },
  {
    value: SLOT.OUTAGE_MONITORING,
    label: `LAUNCHPAD.MODULES.${SLOT.OUTAGE_MONITORING}.name`,
    showFor: ["logs"]
  },
  {
    value: SLOT.ACCOUNTING,
    label: `LAUNCHPAD.MODULES.${SLOT.ACCOUNTING}.name`,
    showFor: ["logs", "reports"]
  },
  {
    value: PLUGIN.STREET_LIGHTING,
    label: `LAUNCHPAD.PLUGINS.${PLUGIN.STREET_LIGHTING}.name`,
    showFor: ["logs"]
  },
  {
    value: PLUGIN.KWP,
    label: `LAUNCHPAD.PLUGINS.${PLUGIN.KWP}.name`,
    showFor: ["logs"]
  },
  {
    value: "overall",
    label: `CONFIGURATION_AREA.TABS.CROSS_MODULE`,
    showFor: ["reports"]
  }
];

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "nb-configuration-area-downloads",
  templateUrl: "./configuration-area-downloads.component.html",
  styleUrls: ["./configuration-area-downloads.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfigurationAreaDownloadsService]
})
export class ConfigurationAreaDownloadsComponent implements OnInit {
  @Input() public logType: "logs" | "reports";

  public modulesList: Array<TabModule> = [];

  public readonly pagingConfig: PaginatorDefaultOptions = {
    pageSize: 20,
    pageSizeOptions: [20, 50, 200],
    showFirstLastButtons: true
  };
  public readonly columnsConfig = configurationAreaDownloadsColumnConfig;

  public readonly vm$: Observable<ConfigurationAreaDownloadsVM>;
  public currentTab$: Observable<string>;

  private readonly moduleChange = new Subject<{ module: LogModules }>();
  private readonly hasMultiChange = new Subject<{ hasMulti: boolean }>();

  private selectedLogs: Array<ModuleConfigLogsItem>;

  constructor(private readonly configurationAreaDownloadsService: ConfigurationAreaDownloadsService) {
    this.vm$ = merge(this.moduleChange.asObservable(), this.hasMultiChange.asObservable(), this.getData()).pipe(
      scan((vm, values) => ({ ...vm, ...values }), { hasMulti: false } as ConfigurationAreaDownloadsVM)
    );
  }

  public ngOnInit(): void {
    this.modulesList = moduleList.filter(({ showFor }) => showFor.includes(this.logType));

    this.currentTab$ = this.moduleChange.pipe(
      startWith({ module: this.modulesList[0].value }),
      map(({ module }) => this.modulesList.find((item) => item.value === module).label)
    );
  }

  public onModuleChange({ tabName }: TabComponent): void {
    this.moduleChange.next({ module: tabName as LogModules });
  }

  public onTableAction(module: SLOT, action: TableAction<ModuleConfigLogsItem>): void {
    if (action.id === "download") {
      this.configurationAreaDownloadsService.downloadLogFile(module, action.row.fileName, this.logType);
    } else if (action.type === "select") {
      this.selectedLogs = action.selected;
      this.hasMultiChange.next({ hasMulti: action.selected.length > 1 });
    }
  }

  public downloadArchive(module: SLOT): void {
    const filesNames = this.selectedLogs.map(({ fileName }) => fileName);
    this.configurationAreaDownloadsService.downloadArchive(module, filesNames, this.logType);
  }

  private getData(): Observable<LoadingResponse<Array<ModuleConfigLogsItem>>> {
    return this.moduleChange.asObservable().pipe(
      switchMap(({ module }) => this.configurationAreaDownloadsService.getLogOrReportList(module, this.logType)),
      map(({ loading, result }) => ({
        loading,
        result: result.map((item) => ({ ...item, size: getFileSize(item.fileSizeInBites) }))
      }))
    );
  }
}
