<tpm-dialog-frame
  *ngIf="contentfulInfo$ | async as contentfulInfo"
  [header]="(contentfulInfo$ | async)?.moduleName | translate"
  [closeButtonLabel]="'APP.CANCEL' | translate"
  [submitButtonLabel]="'APP.SUBMIT_REQUEST' | translate | uppercase"
  [submitDisabled]="leadForm.invalid"
  (submitted)="submit()"
>
  <div class="headline top contentful">
    <h2 class="headline-3">{{ contentfulInfo.headline1 }}</h2>
    <div class="rich-text" [innerHTML]="contentfulInfo.text1"></div>
  </div>
  <div class="left contentful">
    <div class="headline">
      <h2 class="headline-2">{{ contentfulInfo.headline2 }}</h2>
      <div class="rich-text" [innerHTML]="contentfulInfo.text2"></div>
    </div>
    <div class="flyer" *ngIf="contentfulInfo.action" (click)="downloadFlyer(contentfulInfo.action)">
      <mat-icon fontIcon="ec_download"></mat-icon>
      <span>{{ "LEAD.DOWNLOAD_FLYER" | translate : translationParams }}</span
      ><span class="type">(pdf)</span>
    </div>

    <app-contentful-media [asset]="contentfulInfo.media?.fields?.file" class="media"> </app-contentful-media>
  </div>
  <div class="right">
    <div class="contact">
      <h2 class="headline-2">{{ "LEAD.FORM_TITLE" | translate }}</h2>
      <p>{{ "LEAD.FORM_SUBTITLE" | translate }}</p>
      <div class="contact__card" *ngIf="contactPerson$ | async as contactPerson">
        <img class="contact__card-photo" src="/assets/images/logos/tenant-0001.svg" />
        <div class="contact__card-text">
          <h3 class="subtitle-1">{{ "APP.CONTACT_PERSON" | translate }}:</h3>
          <p>
            <span *ngIf="contactPerson.givenName as givenName">{{ givenName }}&nbsp;</span>
            <span *ngIf="contactPerson.familyName as familyName">{{ familyName }}</span>
          </p>
          <p *ngIf="contactPerson.phoneNumber as phoneNumber">Telefon: {{ phoneNumber }}</p>
          <p>
            <span>E-Mail:</span>
            <a class="link-1" href="mailto:{{ contactPerson.email }}" (click)="trackEmail()">
              {{ contactPerson.email }}
            </a>
          </p>
        </div>
      </div>
    </div>
    <form #teaserForm name="teaserForm" class="lead-form" [formGroup]="leadForm">
      <mat-radio-group formControlName="communicationType" name="communicationType">
        <mat-radio-button class="small" [value]="item.id" *ngFor="let item of communicationTypes">
          <b [innerHTML]="item.key | translate"></b>
        </mat-radio-button>
      </mat-radio-group>
      <ng-container [ngSwitch]="communicationType?.value">
        <ng-container *ngSwitchCase="'phone'">
          <tpm-input-field
            placeholder="Telefonnummer eingeben"
            formControlName="phoneNumber"
            [label]="'Telefonnummer'"
          ></tpm-input-field>
          <tpm-checkbox
            class="phonenumber-checkbox"
            density="small"
            formControlName="itsMyPhone"
            [label]="'Hiermit bestätige ich, dass es sich um meine eigene Mobilfunknummer handelt.' | translate"
          ></tpm-checkbox>
        </ng-container>
        <ng-container *ngSwitchCase="'email'">
          <tpm-input-field
            placeholder="E-Mail eingeben"
            formControlName="email"
            [label]="'E-Mail-Adresse'"
          ></tpm-input-field>
        </ng-container>
      </ng-container>
      <tpm-textarea-field
        name="text"
        [label]="'Anmerkungen'"
        formControlName="text"
        placeholder="Text eingeben"
      ></tpm-textarea-field>
      <div class="legal">
        <p class="legal-info" [innerHTML]="'LEAD.LEGAL_INFO' | translate : translationParams"></p>
        <div *ngIf="leadForm.contains('agreement')" class="agreement-checkbox-wrapper">
          <tpm-checkbox density="small" formControlName="agreement"></tpm-checkbox>
          <span [innerHTML]="'LEAD.AGREEMENT_CHECKBOX_LABEL' | translate : agreementTranslationParams"></span>
        </div>
        <p>{{ "LEAD.ENCRYPTION_DISCLAIMER" | translate }}</p>
        <a class="link-1" (click)="openDialog()">{{ "APP.READ_DATAPROTECTION_INFO" | translate }}</a>
      </div>
    </form>
  </div>
</tpm-dialog-frame>
