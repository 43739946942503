<ene-tabs class="main-tabs">
  <ene-tab #consessionTab [tabTitle]="translationKeys.concessionsArea | translate" [active]="true">
    <ng-template [ngIf]="consessionTab.active">
      <ene-tabs class="nested-tabs centered">
        <ene-tab [tabTitle]="'USER_SETTINGS.INSTALLATION_MODULE' | translate">
          <app-modules-tab></app-modules-tab>
        </ene-tab>
        <ene-tab [tabTitle]="'USER_SETTINGS.INCIDENT_MANAGEMENT_MODULE' | translate">
          <ca-notification-settings [module]="'incident-management'"></ca-notification-settings>
        </ene-tab>
        <ene-tab [tabTitle]="'USER_SETTINGS.TYP_MODULE' | translate">
          <ca-notification-settings [module]="'three-year-plan'"></ca-notification-settings>
        </ene-tab>
        <ene-tab [tabTitle]="'USER_SETTINGS.ACCOUNTING_MODULE' | translate">
          <ca-notification-settings [module]="'accounting'"></ca-notification-settings>
        </ene-tab>
        <ene-tab [tabTitle]="'USER_SETTINGS.STREET_LIGHTING_MODULE' | translate">
          <ca-notification-settings [module]="'street-lighting'"></ca-notification-settings>
        </ene-tab>
        <ene-tab [tabTitle]="'USER_SETTINGS.KWP_MODULE' | translate">
          <ca-notification-settings [module]="'kwp'"></ca-notification-settings>
        </ene-tab>
      </ene-tabs>
    </ng-template>
  </ene-tab>
  <ene-tab #logsTab [tabTitle]="translationKeys.downloadsArea | translate">
    <ng-template [ngIf]="logsTab.active">
      <nb-configuration-area-downloads logType="logs"></nb-configuration-area-downloads>
    </ng-template>
  </ene-tab>
  <ene-tab #reportTab [tabTitle]="translationKeys.reportingArea | translate">
    <ng-template [ngIf]="reportTab.active">
      <nb-configuration-area-downloads logType="reports"></nb-configuration-area-downloads>
    </ng-template>
  </ene-tab>
</ene-tabs>
