<ng-container [formGroup]="formGroup">
  <mat-form-field class="settings-form__email-input">
    <mat-label>{{ "NOTIFICATION_SERVICE.EDIT.EMAIL_ADDRESS" | translate }}</mat-label>
    <input matInput formControlName="email" />
    <mat-icon matSuffix fontIcon="ec_edit"></mat-icon>
  </mat-form-field>
  <mat-divider big></mat-divider>
  <div class="settings-form__email-checkboxes">
    <div class="settings-form__checkbox-wrapper">
      <mat-checkbox
        formControlName="intermediate"
        [disableRipple]="true"
        [(indeterminate)]="indeterminate.intermediate"
      >
        {{ "NOTIFICATION_SERVICE.EDIT.KWP.STATUS_CHANGE" | translate }}
      </mat-checkbox>
    </div>
  </div>
</ng-container>
