import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, filter, take } from "rxjs";
import { UserInfoDto } from "src/app/common/dto/user-info";
import { EnvironmentType } from "src/app/common/enums/environmentType";
import { PLUGIN } from "src/app/common/enums/plugins";
import { SLOT } from "src/app/common/enums/slot";
import { AnalyticsUserDimensions } from "src/app/common/utils/analytics-user-dimensions";
import { trackEvent } from "src/app/ngrx/actions/analytics.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectRegionAgs } from "src/app/ngrx/selectors/app.selectors";
import { selectLaunchpadOverlay } from "src/app/ngrx/selectors/overlay.selectors";
import { selectCurrentSlot } from "src/app/ngrx/selectors/router.selectors";
import { getOpenState } from "src/app/ngrx/selectors/sidenavigation.selectors";
import { environment } from "src/environments/environment";
import { AnalyticsEventProps } from "../../analytics/interfaces";
import { MODULES_CRM_IDS_MAP } from "../../constants/modules-crm-ids-map";
import { CrmModule } from "../../enums/permissions";
import { ContentfulContentType } from "../../interfaces/contentful";
import { LaunchpadOverlay } from "../../interfaces/overlay";
import { takeLatestFrom } from "../../utils/ngrx";

const moduleTrackingNameMap: Record<CrmModule, string> = {
  // regulated modules:
  [CrmModule.Accounting]: "Abrechnungen und Verträge",
  [CrmModule.GridConnection]: "Energiedaten und Netzinformationen",
  [CrmModule.GridDevelopment]: "Netzentwicklung",
  [CrmModule.OutageMonitoring]: "Störungsmonitoring",
  [CrmModule.ThreeYearPlan]: "Dreijahresplanung und Bauabstimmung",

  // marketplace modules:
  [CrmModule.Co2Balance]: "co2balance DL",
  [CrmModule.KEM]: "Kommunales Energiemanagement DL",
  [CrmModule.Noysee]: "Noysee DL",
  [CrmModule.ShoppingCart]: "Bestellkorb Öffentliche Beleuchtung DL",
  [CrmModule.StreetLighting]: "Straßenbeleuchtung DL",
  [CrmModule.Diginamic]: "Diginamic DL",
  [CrmModule.CampusOne]: "eCampusKommunal DL",
  [CrmModule.QuickCheck]: "Quick Check DL",
  [CrmModule.KWP]: "KWP DL"
};

/**
 * this type can be used if you don't know if the string is something like
 * `module-xxxxx` or a SLOT or PLUGIN like "grid-development" or "noysee". It
 * can be converted to to a `CrmModule` by using the `toCrmModule()` function.
 */
export type AnyModuleType = CrmModule | SLOT | PLUGIN | `module-${number}`;

export function getModuleTrackingName(module: AnyModuleType): string {
  const crmModule = toCrmModule(module);
  const trackingName = moduleTrackingNameMap[crmModule];
  if (!trackingName && environment.type !== EnvironmentType.PROD) {
    console.warn(`No tracking name found for module ${module}`);
  }
  return trackingName || module;
}

export function toCrmModule(slotOrPlugin: AnyModuleType): CrmModule {
  return slotOrPlugin.startsWith("module-") ? slotOrPlugin : MODULES_CRM_IDS_MAP[slotOrPlugin];
}

@Injectable({ providedIn: "root" })
export class AnalyticsService {
  private isActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly store: Store<RootState>, private readonly translate: TranslateService) {
    this.initTrackAGS();
  }

  public getModuleTrackingName(module: AnyModuleType): string {
    return getModuleTrackingName(module);
  }

  public activate(userInfo: UserInfoDto) {
    (window as any).activateMatomoAnalytics(environment.type, AnalyticsUserDimensions, userInfo);
    this.isActive$.next(true);
  }

  public deactivate() {
    this.isActive$.next(false);
    (window as any).deactivateMatomoAnalytics();
  }

  public trackEvent(props: AnalyticsEventProps): void {
    this.isActive$
      .pipe(
        filter((e) => Boolean(e)),
        take(1)
      )
      .subscribe(() => this.store.dispatch(trackEvent(props)));
  }

  public trackNavigateToSlotFromLaunchpad(slot: SLOT | PLUGIN): void {
    const _slot = takeLatestFrom(this.store, selectCurrentSlot);

    if (slot !== _slot) {
      const slotTranslate = this.getModuleTrackingName(slot);
      this.trackEvent({ category: slotTranslate, action: "Launchpad", name: slotTranslate });
    }
  }

  public trackNavigateToPluginFromLaunchpad(slot: SLOT | PLUGIN): void {
    const pluginTranslate = this.getModuleTrackingName(slot);
    this.trackEvent({ category: pluginTranslate, action: "Launchpad", name: pluginTranslate });
  }

  public trackStorytellerClick(slot: SLOT): void {
    this.trackEvent({
      category: this.getModuleTrackingName(slot),
      action: "Launchpad",
      name: "Storyteller"
    });
  }

  public trackBOPClick(slot: SLOT): void {
    this.trackEvent({
      category: this.getModuleTrackingName(slot),
      action: "BOP",
      name: "BOP"
    });
  }

  public trackSidebarClick(panelName: string): void {
    const isOpened = takeLatestFrom(this.store, getOpenState, { panelName });

    if (isOpened) {
      const module = takeLatestFrom(this.store, selectCurrentSlot) as SLOT;

      this.trackEvent({
        category: this.getModuleTrackingName(module),
        action: this.translate.instant(`TOOLTIP.panelName.${panelName}`),
        name: this.translate.instant(`TOOLTIP.panelName.${panelName}`)
      });
    }
  }

  public trackGlossaryClickInHeader(): void {
    const glossaryTranslate = this.translate.instant("APP.GLOSSARY");
    this.trackEvent({
      category: `${this.translate.instant("TOOLTIP.header.helpMenu")} ${this.getOverlayTranslate()}`,
      action: glossaryTranslate,
      name: glossaryTranslate
    });
  }

  public trackGlossaryTabClick(tabTitle: string): void {
    this.trackEvent({
      category: `${this.translate.instant("TOOLTIP.header.helpMenu")} ${this.getOverlayTranslate()}`,
      action: this.translate.instant("APP.GLOSSARY"),
      name: this.translate.instant(`GLOSSARY_DIALOG.TABS.${tabTitle}`)
    });
  }

  public trackContactTabClick(tab: string): void {
    this.trackEvent({
      category: `${this.translate.instant("TOOLTIP.header.helpMenu")} ${this.getOverlayTranslate()}`,
      action: this.translate.instant("CONTACT_DIALOG.TITLE"),
      name: this.translate.instant(`CONTACT_DIALOG.BUTTON_TOGGLE.${tab}`)
    });
  }

  public trackOpenContactForm(from: string, category?: string): void {
    this.trackEvent({
      category: category || `${this.translate.instant("TOOLTIP.header.helpMenu")} ${this.getOverlayTranslate()}`,
      action: from,
      name: this.translate.instant("CONTACT_DIALOG.TITLE")
    });
  }

  public trackWebinarsClickInHeader(): void {
    const webinarsTranslate = this.translate.instant("APP.WEBINARS");
    this.trackEvent({
      category: `${this.translate.instant("TOOLTIP.header.helpMenu")} ${this.getOverlayTranslate()}`,
      action: webinarsTranslate,
      name: webinarsTranslate
    });
  }

  public trackWebinarsClick(name: string): void {
    this.trackEvent({
      category: `${this.translate.instant("TOOLTIP.header.helpMenu")} ${this.getOverlayTranslate()}`,
      action: this.translate.instant("APP.WEBINARS"),
      name
    });
  }

  public trackPolicyClickInHeader(): void {
    const policyTranslate = this.translate.instant("HEADER.USER.POLICY");
    this.trackEvent({
      category: this.translate.instant("TOOLTIP.header.userMenu"),
      action: policyTranslate,
      name: policyTranslate
    });
  }

  public trackNotificationsClickInHeader(): void {
    const notificationsTranslate = this.translate.instant("HEADER.USER.NOTIFICATIONS");
    this.trackEvent({
      category: this.translate.instant("TOOLTIP.header.userMenu"),
      action: notificationsTranslate,
      name: notificationsTranslate
    });
  }

  public trackNotificationTabClick(tabTitle: string): void {
    this.trackEvent({
      category: "Nutzereinstellungen",
      action: "Benachrichtigungen",
      name: `${tabTitle} Benachrichtigungsoptionen`
    });
  }

  public trackOpenNewsClick(): void {
    this.trackEvent({
      category: this.translate.instant("LAUNCHPAD.NON_MODULES.news.name"),
      action: "Launchpad"
    });
  }

  public trackOpenTeaserDialogClick(slot: SLOT): void {
    this.trackEvent({
      category: this.getModuleTrackingName(slot),
      action: "Launchpad",
      name: "Vertriebsteaser"
    });
  }

  public trackTeaserEmailClick(module: PLUGIN): void {
    this.trackEvent({
      category: "Vertriebsteaser",
      action: this.getModuleTrackingName(module),
      name: "Email"
    });
  }

  public trackTeaserFormSubmit(module: PLUGIN): void {
    this.trackEvent({
      category: this.getModuleTrackingName(module),
      action: "Vertriebsteaser",
      name: "Anfrage"
    });
  }

  public trackNewsTypeChange(contentType: ContentfulContentType): void {
    const contentTypeTranslate = this.translate.instant(`CONTENTFUL.NEWS.TOGGLES.${contentType}`);
    this.trackEvent({
      category: this.translate.instant("LAUNCHPAD.NON_MODULES.news.name"),
      action: contentTypeTranslate,
      name: contentTypeTranslate
    });
  }

  public trackNewsItemClick(contentType: ContentfulContentType, label: string): void {
    this.trackEvent({
      category: this.translate.instant("LAUNCHPAD.NON_MODULES.news.name"),
      action: this.translate.instant(`CONTENTFUL.NEWS.TOGGLES.${contentType}`),
      name: label
    });
  }

  public trackMarkAllReadClick(): void {
    this.trackEvent({
      category: this.translate.instant("LAUNCHPAD.NON_MODULES.news.name"),
      action: "Alles gelesen",
      name: "Alles gelesen"
    });
  }

  public trackNewsActionClick(module: SLOT, action: string): void {
    this.trackEvent({
      category: this.getModuleTrackingName(module),
      action,
      name: this.translate.instant("LAUNCHPAD.NON_MODULES.news.name")
    });
  }

  public trackMapFilterChange(): void {
    const slot = takeLatestFrom(this.store, selectCurrentSlot);

    this.trackEvent({
      category: this.getModuleTrackingName(slot),
      action: this.translate.instant("TOOLTIP.panelName.mapLayers"),
      name: "Filter verändert"
    });
  }

  public trackKpAreaOpen(action: "Launchpad" | "Direktlink", overlay: LaunchpadOverlay): void {
    this.trackEvent({
      category: "KP area",
      action,
      name: { module: "Netzbetrieb", plugin: "Dienstleistung" }[overlay]
    });
  }

  public trackPngModuleOpen(): void {
    this.trackEvent({
      category: "Pachtnetzgesellschaften",
      action: "Zur Kommunen-Verwaltung",
      name: "Wechsel Verwaltungsbereich"
    });
  }

  public trackDirectLink(module: AnyModuleType): void {
    if (!module) {
      return;
    }

    const name = getModuleTrackingName(module);

    if (name === module) {
      // if the slotId is not a CrmModule or SLOT, we don't have a tracking name for it
      return;
    }

    this.trackEvent({
      action: "Direktlink",
      category: name,
      name
    });
  }

  public scanForForms() {
    (window as any).scanForForms();
  }

  public scanForMedia() {
    (window as any).scanForMedia();
  }

  private getOverlayTranslate(): string {
    return takeLatestFrom(this.store, selectLaunchpadOverlay) === "module" ? "" : "DL";
  }

  /**
   * For the report of users per municipality we need to track the ags of the municipality.
   */
  private initTrackAGS() {
    this.store.pipe(select(selectRegionAgs), filter(Boolean)).subscribe((ags) => {
      this.trackEvent({
        category: "report",
        action: "ags",
        name: ags
      });
    });
  }
}
