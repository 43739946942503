import { createSelector } from "@ngrx/store";
import { LaunchpadType } from "src/app/features/overlays/region/launchpad/container/launchpad.interfaces";
import { AdminDataState } from "../reducers/admin-data.reducer";
import { RootState } from "../root-reducers";
import { selectLaunchpadType } from "./overlay.selectors";

const selectFeature = (state: RootState) => state.adminData;

export const selectLaunchpadModules = createSelector(
  selectFeature,
  selectLaunchpadType,
  (state: AdminDataState, launchpadType: LaunchpadType) => state.modules[launchpadType]
);

export const selectMarketplaceModules = createSelector(
  selectFeature,
  (state: AdminDataState) => state.modules.marketplace
);

export const selectLaunchpadModulesByType = (launchpadType: LaunchpadType) =>
  createSelector(selectFeature, (state: AdminDataState) => state.modules[launchpadType]);
