import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { INotificationSettings } from "../../../../../../../interfaces/dialogs/notification-settings";
@Component({
  selector: "app-kwp-notification-settings-edit-email",
  templateUrl: "./notification-settings-edit-email.component.html",
  styleUrls: ["../../style.scss"]
})
export class KwpNotificationSettingsEditEmailComponent {
  @Input() public formGroup: FormGroup;
  @Input() public indeterminate: INotificationSettings;
}
