import { AsyncPipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { marked } from "@enersis/gp-components/gp-markdown";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  startWith,
  take,
  withLatestFrom
} from "rxjs";
import { CardModule } from "src/app/common/components/card/card.module";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";
import { CheckboxModule } from "src/app/common/components/form/checkbox/checkbox.module";
import { IPolicy } from "src/app/common/interfaces/app/policy";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { AuthService } from "src/app/core/services/auth.service";
import { logout, updatePolicy } from "src/app/ngrx/actions/app.actions";
import { selectCurrentPolicy, selectUserInfo } from "src/app/ngrx/selectors/app.selectors";
import { ControlConfigMap } from "../../types/form";
import { TabConfig, TabsComponent } from "../../ui/tabs/tabs.component";

export type PolicyTab = "terms" | "dataProtection" | "cookies";

interface PolicyForm extends ControlConfigMap<Omit<IPolicy, "cookiesDetails">> {
  cookiesDetails: FormGroup<{
    comfort: FormControl<IPolicy["cookiesDetails"]["comfort"]>;
    functional: FormControl<IPolicy["cookiesDetails"]["functional"]>;
    statistic: FormControl<IPolicy["cookiesDetails"]["statistic"]>;
  }>;
}

@Component({
  selector: "tpm-policy-dialog",
  templateUrl: "policy-dialog.component.html",
  styleUrls: ["policy-dialog.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    TranslateModule,
    ReactiveFormsModule,
    DialogFrameModule,
    CardModule,
    TabsComponent,
    CheckboxModule
  ]
})
export class PolicyDialogComponent implements AfterViewInit {
  private readonly dialogRef = inject(MatLegacyDialogRef<PolicyDialogComponent>);
  private readonly store = inject(Store);
  private readonly analytics = inject(AnalyticsService);
  private readonly authService = inject(AuthService);
  private readonly fb = inject(FormBuilder);
  private readonly currentPolicy$ = this.store.select(selectCurrentPolicy);
  private privacyPolicyMarkdown: string;
  private termsOfServiceMarkdown: string;

  private readonly abort$ = new BehaviorSubject<boolean>(false);

  readonly data: { tab: PolicyTab; closable: boolean } = inject(MAT_LEGACY_DIALOG_DATA);
  readonly tabs: Array<TabConfig> = [
    { name: "terms", label: "POLICY_DIALOG.tab.terms" },
    { name: "dataProtection", label: "POLICY_DIALOG.tab.dataProtection" },
    { name: "cookies", label: "POLICY_DIALOG.tab.cookies" }
  ];
  readonly cookies: Array<{ name: string; label: string }> = [
    { name: "functional", label: "POLICY_DIALOG.cookies.functional" },
    { name: "statistic", label: "POLICY_DIALOG.cookies.statistic" },
    { name: "comfort", label: "POLICY_DIALOG.cookies.comfort" }
  ];
  readonly form = this.fb.group<PolicyForm>({
    version: ["v2", Validators.required],
    termsAgreements: [false, Validators.requiredTrue],
    cookiesAgreements: [{ value: false, disabled: true }, Validators.requiredTrue],
    cookiesDetails: this.fb.group({
      functional: [{ value: true, disabled: true }, Validators.requiredTrue],
      statistic: [false],
      comfort: [false]
    })
  });

  readonly tab$ = new BehaviorSubject<PolicyTab>(this.data.tab ?? "terms");

  readonly submitDisabled$ = combineLatest([
    this.abort$.asObservable(),
    this.form.valueChanges.pipe(startWith({}))
  ]).pipe(map(([abort]) => !abort && this.form.invalid));

  readonly warning$ = this.abort$.asObservable().pipe(map((abort) => (abort ? "POLICY_DIALOG.cookies.warning" : null)));
  readonly submitButtonLabel$ = this.abort$
    .asObservable()
    .pipe(map((abort) => (abort ? "POLICY_DIALOG.submitButtonBackLabel" : "POLICY_DIALOG.submitButtonAgreeLabel")));
  readonly closeButtonLabel$ = this.abort$
    .asObservable()
    .pipe(map((abort) => (abort ? "POLICY_DIALOG.closeButtonLogoutLabel" : "POLICY_DIALOG.closeButtonLabel")));

  readonly cookiesLabels$: Observable<[string, string]>;

  constructor() {

    this.cookiesLabels$ = combineLatest([
      this.tab$,
      this.cookiesDetailsFormGroup.valueChanges.pipe(startWith(this.cookiesDetailsFormGroup.value))
    ]).pipe(
      map(([tab, values]) =>
        Object.values(values).every((value) => value === true) ||
          (tab !== "cookies" && this.cookiesDetailsFormGroup.untouched)
          ? ["POLICY_DIALOG.cookies.checkboxLabelAll", "POLICY_DIALOG.cookies.linkLabelAll"]
          : ["POLICY_DIALOG.cookies.checkboxLabelSome", "POLICY_DIALOG.cookies.linkLabelSome"]
      )
    );

    this.termsAgreementsValueChanges();
    this.cookiesAgreementsValueChanges();
  }

  ngOnInit(): void {
    this.currentPolicy$.pipe(take(1)).subscribe(policy => {
      if (policy) {

        const markedOptions = {
          breaks: true,
        }

        this.termsOfServiceMarkdown = marked(this.decodeEscapedString(policy.termsOfService), markedOptions);
        this.privacyPolicyMarkdown = marked(this.decodeEscapedString(policy.privacyPolicy), markedOptions);

        const hasPolicy = this.authService.hasPolicy(policy.version);
        if (hasPolicy) {
          this.form.patchValue(this.authService.getPolicyCookies());
          this.termsAgreementsControl.disable();
          this.cookiesAgreementsControl.enable();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.analytics.scanForForms();
  }

  onTabChange(tab: PolicyTab): void {
    this.tab$.next(tab);
  }

  redirectToCookies(): void {
    this.onTabChange("cookies");
  }

  onClose(): void {
    this.currentPolicy$.pipe(take(1)).subscribe(policy => {
      if (this.form.valid && this.authService.hasPolicy(policy.version)) {
        this.dialogRef.close();
      } else {
        if (this.abort$.getValue() === true) {
          this.authService.deletePolicyCookies();
          this.store.dispatch(logout());
        } else {
          this.abort$.next(true);
        }
      }
    });
  }

  onSubmit(): void {
    if (this.abort$.getValue() === true) {
      this.abort$.next(false);
    } else {
      if (this.form.valid) {
        if (this.cookiesDetailsFormGroup.value.statistic) {
          of(null)
            .pipe(withLatestFrom(this.store.select(selectUserInfo)), take(1))
            .subscribe(([_, userInfo]) => {
              this.analytics.activate(userInfo);
            });
        } else {
          this.analytics.deactivate();
        }

        this.store
          .select(selectCurrentPolicy)
          .pipe(
            take(1)
          )
          .subscribe((currentPolicy) => {
            this.store.dispatch(updatePolicy({ policy: { ...this.form.getRawValue(), version: currentPolicy.version } })); // todo fix object to number
            this.dialogRef.close();
          });
      }
    }
  }

  private termsAgreementsValueChanges(): void {
    this.termsAgreementsControl.valueChanges.pipe(distinctUntilChanged(), takeUntilDestroyed()).subscribe((state) => {
      if (state) {
        this.cookiesAgreementsControl.enable();
      } else {
        this.cookiesAgreementsControl.disable();
      }
    });
  }

  private cookiesAgreementsValueChanges(): void {
    this.cookiesAgreementsControl.valueChanges.pipe(distinctUntilChanged(), takeUntilDestroyed()).subscribe((state) => {
      if (state) {
        this.termsAgreementsControl.disable();

        if (this.cookiesDetailsFormGroup.untouched) {
          if (this.tab$.getValue() !== "cookies") {
            this.cookiesDetailsFormGroup.patchValue({ functional: true, statistic: true, comfort: true });
          }
          this.cookiesDetailsFormGroup.markAsTouched();
        }
      } else {
        this.termsAgreementsControl.enable();
      }
    });
  }

  private get termsAgreementsControl(): AbstractControl<boolean> {
    return this.form.get("termsAgreements");
  }

  private get cookiesAgreementsControl(): AbstractControl<boolean> {
    return this.form.get("cookiesAgreements");
  }

  private get cookiesDetailsFormGroup(): AbstractControl<{
    functional: boolean;
    statistic: boolean;
    comfort: boolean;
  }> {
    return this.form.get("cookiesDetails");
  }

  private decodeEscapedString(input: string): string {
    return input.replace(/\\"/g, '"').replace(/\\\\/g, '\\');
  }
}
