export enum CrmModule {
  GridDevelopment = "module-00004",
  OutageMonitoring = "module-00005",
  GridConnection = "module-00006",
  ThreeYearPlan = "module-00007",
  Accounting = "module-00008",
  StreetLighting = "module-00009",
  KEM = "module-00010",
  Noysee = "module-00011",
  ShoppingCart = "module-00012",
  Co2Balance = "module-00000",
  CampusOne = "module-00015",
  Diginamic = "module-00017",
  QuickCheck = "module-00019",
  KWP = "module-00020"
}
