import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from "@angular/core";
import { CarouselModule } from "src/app/common/components/tpm-carousel/carousel.module";
import { CommunalPlatformNewsEntry, NetzeBwNewsEntry } from "../../../../news/news.interfaces";
import { LaunchpadMarketplaceBannerProps, LaunchpadType } from "../../../container/launchpad.interfaces";
import { MarketplaceBannerComponent } from "../marketplace-banner/marketplace-banner.component";
import { NewsBannerComponent } from "../news-banner/news-banner.component";

@Component({
  selector: "tpm-banners-carousel",
  templateUrl: "banners-carousel.component.html",
  styleUrls: ["banners-carousel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, CarouselModule, MarketplaceBannerComponent, NewsBannerComponent]
})
export class BannersCarouselComponent {
  @Output() public readonly newsBannerClicked = new EventEmitter<NetzeBwNewsEntry | CommunalPlatformNewsEntry>();
  @Output() public readonly marketplaceBannerClicked = new EventEmitter<LaunchpadMarketplaceBannerProps>();

  @Input() public marketplaceList: Array<any> | undefined;
  @Input() public newsList: Array<any> | undefined;
  @Input() public overlay: LaunchpadType;
  @Input() public isMobile: boolean | undefined;
  @Input() public isMedium: boolean | undefined;

  public readonly trackByIndex: TrackByFunction<unknown> = (index) => index;
}
