import { Injectable, inject } from "@angular/core";
import { CrmModule } from "src/app/core/enums/permissions";
import { AnalyticsService, AnyModuleType } from "src/app/core/services/analytics/analytics.service";
import { LaunchpadType } from "./container/launchpad.interfaces";

@Injectable()
export class LaunchpadAnalyticsService {
  private readonly analyticsCore = inject(AnalyticsService);

  public trackTileClick(value: AnyModuleType): void {
    this.analyticsCore.trackEvent({
      category: this.analyticsCore.getModuleTrackingName(value),
      action: "Launchpad",
      name: this.analyticsCore.getModuleTrackingName(value)
    });
  }

  public trackStoryTellerClick(value: CrmModule | `module-${number}`): void {
    this.analyticsCore.trackEvent({
      category: this.analyticsCore.getModuleTrackingName(value),
      action: "Launchpad",
      name: "Storyteller"
    });
  }

  public trackNewsLinkCardClick(): void {
    this.analyticsCore.trackEvent({
      category: "News",
      action: "Launchpad",
      name: "Netze BW"
    });
  }

  public trackSubscriptionLinkCardClick(): void {
    this.analyticsCore.trackEvent({
      category: "Nutzereinstellungen",
      action: "Launchpad",
      name: "Benachrichtigungen"
    });
  }

  public trackNewsCarouselSlideClick(title: string, overlay: LaunchpadType): void {
    this.analyticsCore.trackEvent({
      category: "News" + (overlay === "marketplace" ? " DL" : ""),
      action: "Launchpad",
      name: title
    });
  }

  public trackMarketCarouselSlideClick(title: string): void {
    this.analyticsCore.trackEvent({
      category: "News DL",
      action: "Werbebanner",
      name: title
    });
  }

  public trackBannerCarouselSlideClick(module: CrmModule | `module-${number}`): void {
    this.analyticsCore.trackEvent({
      category: this.analyticsCore.getModuleTrackingName(module),
      action: "Werbebanner",
      name: "Vertriebsteaser"
    });
  }

  public trackLeadButtonClick(value: CrmModule | `module-${number}`): void {
    this.analyticsCore.trackEvent({
      category: this.analyticsCore.getModuleTrackingName(value),
      action: "Launchpad",
      name: "Vertriebsteaser"
    });
  }
}
